<template>
  <v-container fill-height height="100vh">
    <v-layout column fill-height wrap height="100vh">
      <v-container
        style="width: 90%; max-width: 450px; max-height=90vh;"
        class="mx-auto pa-1"
      >
        <v-row v-if="agencyPhoneNumber">
          <v-col class="ma-0 pa-0">
            <TelButton :agencyTel="agencyPhoneNumber" buttonClass="float-right" />
          </v-col>
        </v-row>
        <v-card-title class="font-weight-bold pb-2">
          {{ $t('title.contractor.login') }}
        </v-card-title>
        <v-container>
          <ValidationForms ref="forms">
            <template v-if="isPolicyNumber">
              <p
                v-for="(message, index) in splitMessageLine($t('description.identificationInPolicyNumberNotes'))"
                :key="index"
                class="ma-0 px-0 pb-3"
              >
                {{ message }}
              </p>
              <v-row class="mb-0">{{ $t('label.policyNumber') }}</v-row>
              <v-row class="ml-1 mb-2" style="font-size:14px"
                ><p>
                  {{ $t('description.login.policyNumberExplanation') }}
                </p></v-row
              >
              <v-row style="width: 90%; max-width: 350px;" class="mx-auto">
                <ValidationForm :rules="`required|pattern:policyNumber`">
                  <v-text-field
                    v-model="sendData.policyNumber"
                    type="text"
                    :label="$t('label.policyNumber')"
                    dense
                    hide-details
                    @keyup.enter="onConvertToHalfWidth('policyNumber')"
                    @blur="onConvertToHalfWidth('policyNumber')"
                    @compositionend="onConvertToHalfWidth('policyNumber')"
                  ></v-text-field>
                </ValidationForm>
              </v-row>
            </template>
            <template v-else>
              <p
                v-for="(message, index) in splitMessageLine($t('description.identificationInDateOfBirthNotes'))"
                :key="index"
                class="ma-0 px-0 pb-3"
              >
                {{ message }}
              </p>
              <v-row>{{ $t('label.dateOfBirth') }}</v-row>
              <v-row style="width: 90%; max-width: 350px;" class="mx-auto">
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  width="100%"
                  max-width="350px"
                >
                  <template v-slot:activator="{ on }">
                    <ValidationForm rules="required">
                      <v-text-field
                        v-model="sendData.dateOfBirth"
                        :label="$t('label.dateOfBirth')"
                        prepend-icon="event"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </ValidationForm>
                  </template>
                  <v-date-picker
                    ref="picker"
                    locale="jp-ja"
                    v-model="sendData.dateOfBirth"
                    :day-format="date => new Date(date).getDate()"
                    :max="new Date().toISOString().substr(0, 10)"
                    @change="save"
                    color="calendar"
                    full-width
                  ></v-date-picker>
                </v-menu>
              </v-row>
            </template>
          </ValidationForms>
          <template v-if="isError">
            <v-row style="width: 90%; max-width: 350px;" class="mx-auto">
              <p class="font-weight-bold" style="color:red">
                {{ $t('error.selfCheckError') }}
              </p>
            </v-row>
          </template>
        </v-container>
        <v-row class="my-2 justify-center">
          <v-col style="text-align:center">
            <v-btn
              rounded
              class="font-weight-black mx-auto"
              align="center"
              width="30vw"
              max-width="120px"
              color="back"
              style="font-size:20px"
              dark
              href="/"
              >{{ $t('button.back') }}</v-btn
            >
          </v-col>
          <v-col style="text-align:center">
            <v-btn
              rounded
              class="font-weight-black mx-auto"
              align="center"
              width="30vw"
              max-width="120px"
              color="next"
              style="font-size:20px"
              :dark="!isConnecting"
              :disabled="isConnecting"
              @click="onLoginClick"
              >{{ $t('button.inputComplete') }}</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-layout>
  </v-container>
</template>

<script>
import { AuthType, DefaultDateOfBirth, HttpStatus, ReverseAuthIdentifier } from '@/lib/const';
import TelButton from '@/components/molecules/contractor/TelButton';
import ValidationForms from '@/components/organisms/common/ValidationForms';
import ValidationForm from '@/components/organisms/common/ValidationForm';
import { mapGetters, mapActions } from 'vuex';
import { certifications } from '@/apis/contractor/certifications';
import { splitMessageLine } from '@/lib/commonUtil';
import { getAgencies } from '@/apis/contractor/agencies';

export default {
  name: 'Login',
  components: {
    TelButton,
    ValidationForms,
    ValidationForm,
  },
  data: () => ({
    sendData: {
      dateOfBirth: null,
      policyNumber: null,
    },
    menu: false,
    isError: false,
    isPolicyNumber: null,

    isConnecting: false,

    agencyPhoneNumber: '',
  }),
  async mounted() {
    this.clearAccessToken();
    const authType = ReverseAuthIdentifier[this.urlToken().slice(0, 1)];
    this.$set(this, 'isPolicyNumber', authType === AuthType.policyNumber);

    const agency = await getAgencies().catch(() => {});
    if(agency)
      this.$set(this, 'agencyPhoneNumber', agency.agencyPhoneNumber);
  },
  methods: {
    ...mapGetters('contractor', ['urlToken']),
    ...mapActions('contractor', ['clearAccessToken']),
    ...mapActions('temporarily', ['setContractorConfirmData', 'setSendData']),

    async onLoginClick() {
      //  日付ピッカー操作時に未入力のエラーが常時表示されるため、バリデーションルールは直前で追加する
      this.$set(this, 'isConnecting', true);
      if (await this.$refs.forms.validate()) {
        const request = this.isPolicyNumber ? {
          policyNumber: this.sendData.policyNumber
        } : {
          dateOfBirth: this.sendData.dateOfBirth
        };
        const response = await certifications(
          this.urlToken(),
          request
        ).catch(err => {
          // ロックアウト時はロックアウト画面に遷移
          if (err.status === HttpStatus.Locked) {
            this.$router.push('/lockout');
          } else {
            this.$set(this, 'isError', true);
          }
        });
        if (response) {
          this.$set(this, 'isError', false);
          this.setContractorConfirmData(this.sendData);
          this.setSendData(response);
          this.$router.push('/auth');
        }
      }
      this.$set(this, 'isConnecting', false);
    },
    save(date) {
      this.$refs.menu.save(date);
      // 再入力に備えて、入力が終わったら同期する
      this.pickerDate = date;
    },
    splitMessageLine(message) {
      return splitMessageLine(message.join('\n'));
    },
    onConvertToHalfWidth(key) {
      // 全角英数字を半角英数字に自動変換
      if (this.sendData[key])
        this.sendData[key] = this.sendData[key].replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) {
          return String.fromCharCode(s.charCodeAt(0) - 65248);
        });
    },
  },
  watch: {
    menu(val) {
      val &&
        setTimeout(
          () => (
            // 未入力時は年から選ぶようにする
            (this.$refs.picker.activePicker = this.pickerDate
              ? 'DATE'
              : 'YEAR'),
            // 選択値が存在しない場合はデフォルトを表示
            (this.$refs.picker.tableDate = this.pickerDate
              ? this.pickerDate
              : DefaultDateOfBirth)
          )
        );
    },
  },
};
</script>

<style>
.v-date-picker-table .v-btn__content {
  font-size: 25px !important;
}
</style>
