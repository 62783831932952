var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { "fill-height": "", height: "100vh" } },
    [
      _c(
        "v-layout",
        { attrs: { column: "", "fill-height": "", wrap: "", height: "100vh" } },
        [
          _c(
            "v-container",
            {
              staticClass: "mx-auto pa-1",
              staticStyle: { width: "90%", "max-width": "450px" }
            },
            [
              _vm.agencyPhoneNumber
                ? _c(
                    "v-row",
                    [
                      _c(
                        "v-col",
                        { staticClass: "ma-0 pa-0" },
                        [
                          _c("TelButton", {
                            attrs: {
                              agencyTel: _vm.agencyPhoneNumber,
                              buttonClass: "float-right"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c("v-card-title", { staticClass: "font-weight-bold pb-2" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("title.contractor.login")) +
                    "\n      "
                )
              ]),
              _c(
                "v-container",
                [
                  _c(
                    "ValidationForms",
                    { ref: "forms" },
                    [
                      _vm.isPolicyNumber
                        ? [
                            _vm._l(
                              _vm.splitMessageLine(
                                _vm.$t(
                                  "description.identificationInPolicyNumberNotes"
                                )
                              ),
                              function(message, index) {
                                return _c(
                                  "p",
                                  { key: index, staticClass: "ma-0 px-0 pb-3" },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(message) +
                                        "\n            "
                                    )
                                  ]
                                )
                              }
                            ),
                            _c("v-row", { staticClass: "mb-0" }, [
                              _vm._v(_vm._s(_vm.$t("label.policyNumber")))
                            ]),
                            _c(
                              "v-row",
                              {
                                staticClass: "ml-1 mb-2",
                                staticStyle: { "font-size": "14px" }
                              },
                              [
                                _c("p", [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(
                                        _vm.$t(
                                          "description.login.policyNumberExplanation"
                                        )
                                      ) +
                                      "\n              "
                                  )
                                ])
                              ]
                            ),
                            _c(
                              "v-row",
                              {
                                staticClass: "mx-auto",
                                staticStyle: {
                                  width: "90%",
                                  "max-width": "350px"
                                }
                              },
                              [
                                _c(
                                  "ValidationForm",
                                  {
                                    attrs: {
                                      rules: "required|pattern:policyNumber"
                                    }
                                  },
                                  [
                                    _c("v-text-field", {
                                      attrs: {
                                        type: "text",
                                        label: _vm.$t("label.policyNumber"),
                                        dense: "",
                                        "hide-details": ""
                                      },
                                      on: {
                                        keyup: function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.onConvertToHalfWidth(
                                            "policyNumber"
                                          )
                                        },
                                        blur: function($event) {
                                          return _vm.onConvertToHalfWidth(
                                            "policyNumber"
                                          )
                                        },
                                        compositionend: function($event) {
                                          return _vm.onConvertToHalfWidth(
                                            "policyNumber"
                                          )
                                        }
                                      },
                                      model: {
                                        value: _vm.sendData.policyNumber,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.sendData,
                                            "policyNumber",
                                            $$v
                                          )
                                        },
                                        expression: "sendData.policyNumber"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                        : [
                            _vm._l(
                              _vm.splitMessageLine(
                                _vm.$t(
                                  "description.identificationInDateOfBirthNotes"
                                )
                              ),
                              function(message, index) {
                                return _c(
                                  "p",
                                  { key: index, staticClass: "ma-0 px-0 pb-3" },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(message) +
                                        "\n            "
                                    )
                                  ]
                                )
                              }
                            ),
                            _c("v-row", [
                              _vm._v(_vm._s(_vm.$t("label.dateOfBirth")))
                            ]),
                            _c(
                              "v-row",
                              {
                                staticClass: "mx-auto",
                                staticStyle: {
                                  width: "90%",
                                  "max-width": "350px"
                                }
                              },
                              [
                                _c(
                                  "v-menu",
                                  {
                                    ref: "menu",
                                    attrs: {
                                      "close-on-content-click": false,
                                      transition: "scale-transition",
                                      "offset-y": "",
                                      width: "100%",
                                      "max-width": "350px"
                                    },
                                    scopedSlots: _vm._u([
                                      {
                                        key: "activator",
                                        fn: function(ref) {
                                          var on = ref.on
                                          return [
                                            _c(
                                              "ValidationForm",
                                              { attrs: { rules: "required" } },
                                              [
                                                _c(
                                                  "v-text-field",
                                                  _vm._g(
                                                    {
                                                      attrs: {
                                                        label: _vm.$t(
                                                          "label.dateOfBirth"
                                                        ),
                                                        "prepend-icon": "event",
                                                        readonly: ""
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.sendData
                                                            .dateOfBirth,
                                                        callback: function(
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.sendData,
                                                            "dateOfBirth",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "sendData.dateOfBirth"
                                                      }
                                                    },
                                                    on
                                                  )
                                                )
                                              ],
                                              1
                                            )
                                          ]
                                        }
                                      }
                                    ]),
                                    model: {
                                      value: _vm.menu,
                                      callback: function($$v) {
                                        _vm.menu = $$v
                                      },
                                      expression: "menu"
                                    }
                                  },
                                  [
                                    _c("v-date-picker", {
                                      ref: "picker",
                                      attrs: {
                                        locale: "jp-ja",
                                        "day-format": function(date) {
                                          return new Date(date).getDate()
                                        },
                                        max: new Date()
                                          .toISOString()
                                          .substr(0, 10),
                                        color: "calendar",
                                        "full-width": ""
                                      },
                                      on: { change: _vm.save },
                                      model: {
                                        value: _vm.sendData.dateOfBirth,
                                        callback: function($$v) {
                                          _vm.$set(
                                            _vm.sendData,
                                            "dateOfBirth",
                                            $$v
                                          )
                                        },
                                        expression: "sendData.dateOfBirth"
                                      }
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          ]
                    ],
                    2
                  ),
                  _vm.isError
                    ? [
                        _c(
                          "v-row",
                          {
                            staticClass: "mx-auto",
                            staticStyle: { width: "90%", "max-width": "350px" }
                          },
                          [
                            _c(
                              "p",
                              {
                                staticClass: "font-weight-bold",
                                staticStyle: { color: "red" }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.$t("error.selfCheckError")) +
                                    "\n            "
                                )
                              ]
                            )
                          ]
                        )
                      ]
                    : _vm._e()
                ],
                2
              ),
              _c(
                "v-row",
                { staticClass: "my-2 justify-center" },
                [
                  _c(
                    "v-col",
                    { staticStyle: { "text-align": "center" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "font-weight-black mx-auto",
                          staticStyle: { "font-size": "20px" },
                          attrs: {
                            rounded: "",
                            align: "center",
                            width: "30vw",
                            "max-width": "120px",
                            color: "back",
                            dark: "",
                            href: "/"
                          }
                        },
                        [_vm._v(_vm._s(_vm.$t("button.back")))]
                      )
                    ],
                    1
                  ),
                  _c(
                    "v-col",
                    { staticStyle: { "text-align": "center" } },
                    [
                      _c(
                        "v-btn",
                        {
                          staticClass: "font-weight-black mx-auto",
                          staticStyle: { "font-size": "20px" },
                          attrs: {
                            rounded: "",
                            align: "center",
                            width: "30vw",
                            "max-width": "120px",
                            color: "next",
                            dark: !_vm.isConnecting,
                            disabled: _vm.isConnecting
                          },
                          on: { click: _vm.onLoginClick }
                        },
                        [_vm._v(_vm._s(_vm.$t("button.inputComplete")))]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }